import * as React from "react";

import SEO from "../../components/seo";
import NavBar from "../../components/navBar";
import Footer from "../../components/Footer";
import ProductRow from "../../components/ProductRow";
import AppStoreDownload from "../../components/AppStoreDownload";
import PlayStoreDownload from "../../components/PlayStoreDownload";
import WurdleDownload from "../../images/wurdle_download.png";

// styles
const pageStyles = {
  color: "#232129",
  // padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO
        title="Wurdle with Friends"
        description="Enjoy Wordle but want to make it more social with your inner circle? Compete with friends in Wordle!"
        image={WurdleDownload}
      />
      <NavBar />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="mb-12 sm:-mx-5 -my-2 flex gap-4 justify-center">
          <AppStoreDownload
            href="https://apps.apple.com/us/app/wurdle-with-friends/id1616260888"
            eventName="app_store_clicked_wurdle"
          />
          <PlayStoreDownload
            href="https://play.google.com/store/apps/details?id=io.one_step.wordle_tracker"
            eventName="play_store_clicked_wurdle"
          />
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Wurdle with Friends
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Enjoy Wordle but want to make it more social with your inner
              circle? Compete with friends in Wordle!
            </p>
          </div>
          <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
            <p>
              See how you rank against your friends in the live weekly
              scoreboard. You can join multiple friend groups or just keep track
              of your own scores over time.
            </p>
            <p>
              Download, create a group and invite your friends to compete in
              Wordle!
            </p>
            {/* <p>
              This app is free and requires no email account to get setup. Your
              data will sync to a your device and a server.
            </p> */}
          </div>
        </div>
        <ProductRow products={products} />
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;

const products = [
  {
    id: 1,
    href: "https://apps.apple.com/us/app/wurdle-with-friends/id1616260888",
    imageSrc:
      "https://is2-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/aa/71/ce/aa71ce19-8d4c-8a2f-8960-c69e7f185a1d/8e34ad01-889e-4d3d-87f2-dbdb0384ec15_1.png/920x0w.webp",
    eventName: "image_1_wurdle_clicked",
  },
  {
    id: 2,
    href: "https://apps.apple.com/us/app/wurdle-with-friends/id1616260888",
    imageSrc:
      "https://is2-ssl.mzstatic.com/image/thumb/PurpleSource126/v4/e8/04/7c/e8047cf1-3059-3e56-dde3-659dda064d96/5ba1ac91-5fd2-408b-8f9a-a085cff4b5c7_2.png/920x0w.webp",
    eventName: "image_2_wurdle_clicked",
  },
  {
    id: 3,
    href: "https://apps.apple.com/us/app/wurdle-with-friends/id1616260888",
    imageSrc:
      "https://is5-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/41/38/fb/4138fb5e-9248-1be4-5f85-e029bd7d4e14/4de3fee1-9ecc-429f-a130-718f28e93ee4_3.png/920x0w.webp",
    eventName: "image_3_wurdle_clicked",
  },
];
